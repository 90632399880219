<template>
    <div className="edit-quill" id="editor" style="height: 100%;">
        <QuillEditor
            ref="editor"
            theme="bubble"
            placeholder="Вставьте сюда текст перед&nbsp;публикацией и&nbsp;посмотрите, на&nbsp;что&nbsp;форма&nbsp;обратит внимание."
            :options="options"
            @textChange="textChange"
            @click="errorClick"
            @selectionChange="selectionChange"
            @paste="pasteTextFromClipboard"
        />
    </div>
</template>

<script>
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'
import Delta from "quill-delta"

export default {
    components: {
        QuillEditor
    },
    name: 'TextArea',
    data() {
        return {
            options: {
                modules: {
                    keyboard: {
                        bindings: {
                            'list autofill': {
                                prefix: /^\s{0,}(1){1,1}(\.|-|\*|\[ ?\]|\[x\])$/
                            }
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.$eventHub.on('clickOnTheFixButton', (tip) => {
            this.fixIssue(tip)
        })
        this.$eventHub.on('clickOnTheFixAllButton', (tip) => {
            this.fixIssues(tip)
        })
        this.$eventHub.on('mouseClickOnSelectedText', () => {
            setTimeout(() => {
                this.dropIssuesMarks()
                this.markIssues()
            }, 100)
        })
        this.$eventHub.on('hoveringTextCursorOverSelectedText', () => {
            setTimeout(() => {
                this.dropIssuesMarks()
                this.markIssues()
            }, 100)
        })
        this.$eventHub.on('clickOnTheClipboardButton', () => {
            this.copyTextToClipboard()
        })
    },
    methods: {
        findQuillCoordinates() {
            let elem = document.querySelector(".ql-editor");
            return elem.getBoundingClientRect();
        },
        errorClick(event) {
            this.$eventHub.emit('hideTips');
            let x = event.clientX
            let y = event.clientY
            this.$store.getters.bounds.forEach((el) => {
                if (el.left < x && x < el.right && el.top < y && y < el.bottom) {
                    this.$eventHub.emit('mouseClickOnSelectedText', el.id);
                }
            })
        },
        async pasteTextFromClipboard(clipboardEvent) {
            clipboardEvent.preventDefault()
            const quill = this.$refs.editor.getQuill()
            let currText = quill.getText()
            let clipboardText = clipboardEvent.clipboardData.getData('text/plain')
            if (clipboardText.length == 0) {
                let clipboardHtml = clipboardEvent.clipboardData.getData('text/html')
                clipboardText = clipboardHtml
                    .replaceAll('<br', '\n\n<br')
                    .replaceAll('<p', '\n\n<p')
                    .replace(/<[^>]*>/g, '')
                    .trim();
            }
            let prevIndex = 0
            let range = quill.getSelection(true)
            if (range) {
                prevIndex = range.index
                let head = currText.substring(0, range.index)
                let tail = currText.substring(range.index + range.length, currText.length - 1)
                quill.deleteText(0, quill.getLength())
                setTimeout(() =>
                    quill.insertText(0, head + clipboardText + tail, 'user'), 5
                )
            } else {
                quill.insertText(0, '\n', 'user')
            }
            quill.update('user')
            setTimeout(() => quill.setSelection(prevIndex + clipboardText.length, 0, 'user'), 5)
            await this.refreshTextArea()
        },
        selectionChange(range) {
            if (range.source === 'user' && !!range.range && !!range.range.index) {
                let index = range.range.index;
                this.$store.getters.tips.forEach((el) => {
                    if (index >= el.index && index < el.index + el.size) {
                        this.$eventHub.emit('hoveringTextCursorOverSelectedText', el.id);
                    }
                })
            }
        },
        async fixIssue(tip) {
            this.dropIssuesMarks()

            let change = new Delta();
            change.retain(tip.index);
            change.delete(tip.size);
            tip.correct.forEach((el) => {
                change.insert(el);
            })
            let quill = this.$refs.editor.getQuill();
            quill.updateContents(change);

            quill.update()
            this.$store.dispatch('dropActiveTip')
            await this.refreshTips()
            this.markIssues()
        },
        async fixIssues(tip) {
            this.dropIssuesMarks()

            const mark = tip.mark
            let quill = this.$refs.editor.getQuill();
            let invertedTips = this.$store.getters.tips.reverse();
            invertedTips.forEach((el) => {
                if (el.mark && el.mark === mark) {
                    let change = new Delta();
                    change.retain(el.index);
                    change.delete(el.size);
                    el.correct.forEach((e) => {
                        change.insert(e);
                    })
                    quill.updateContents(change);
                }
            })

            quill.update()
            this.$store.dispatch('dropActiveTip')
            await this.refreshTips()
            this.markIssues()
        },
        dropIssuesMarks() {
            const quill = this.$refs.editor.getQuill();
            quill.formatText(0, quill.getLength(), {underline: false, bold: false}, 'silent')
            quill.formatText(0, quill.getLength(), {bold: false, underline: false}, 'silent') // @todo check this
            quill.update()
        },
        markIssues() {
            const quill = this.$refs.editor.getQuill();
            const regularIssueFormat = {underline: true}
            const activeIssueFormat = {bold: true}
            this.$store.getters.tips.forEach((el) => {
                let change = new Delta();
                change.retain(el.index);
                change.retain(el.size, el.active ? activeIssueFormat : regularIssueFormat);
                quill.updateContents(change);
            })
            quill.update()
        },
        async textChange(delta) {
            if (delta.source === 'user') {
                const quill = this.$refs.editor.getQuill()
                await this.refreshTextArea()
                const range = quill.getSelection()
                if (range && range.length === 0) {
                    let index = range.index
                    this.$store.getters.tips.forEach((el) => {
                        if (index >= el.index && index < el.index + el.size) {
                            this.$eventHub.emit('hoveringTextCursorOverSelectedText', el.id);
                        }
                    })
                }
                this.$store.dispatch('setIsTextInserted', quill.getLength() > 1)
                if (quill.getLength() <= 1) {
                    this.$store.dispatch('clearTips')
                }
            }
        },
        async refreshTextArea() {
            const quill = this.$refs.editor.getQuill()
            quill.update()
            this.dropIssuesMarks()
            await this.refreshTips()
            this.markIssues()
        },
        async refreshTips() {
            const quill = this.$refs.editor.getQuill()
            quill.update()
            const quillText = quill.getText();
            await this.$store.dispatch('fetchTips', quillText);
            let rect = this.findQuillCoordinates()
            this.$store.dispatch('clearBounds')
            this.$store.getters.tips.forEach((el) => {
                let bounds = quill.getBounds(el.index, el.size)
                bounds.inner_top = bounds.top
                bounds.top += rect.top
                bounds.left += rect.left
                bounds.right = bounds.left + bounds.width
                bounds.bottom = bounds.top + bounds.height
                bounds.id = el.id
                this.$store.dispatch('addBounds', bounds)
            })
        },
        async copyTextToClipboard() {
            const quillText = this.$refs.editor.getText();
            await navigator.clipboard.writeText(quillText)
        }
    }
}
</script>

<style>
.ql-container {
    padding: 15px 70px 15px 5px;
    font-size: 1rem;
}
.ql-editor {
    padding: 0;
}

.ql-editor > p,
.ql-editor.ql-blank::before {
    top: 14px;
    left: 5px;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.33;
    font-family: 'P22UndergroundCYBook', Helvetica Neue, Arial, sans-serif;
}

@media only screen and (max-width: 767px) {
    .ql-container {
        padding: 13px 15px 15px 5px;
        font-size: 1.1rem;
        max-height: 66.67vh;
    }
    .ql-editor > p,
    .ql-editor.ql-blank::before {
        top: 14px;
        left: 5px;
        font-size: 1.1rem;
    }
}

.ql-editor.ql-blank::before {
    color: rgba(0,0,0,0.3);
}
.ql-blank {
    margin: 0;
    padding: 0;
    tab-size: 0;
    white-space: inherit;
}
.ql-blank::before {
    margin: 0;
}
.ql-editor u {
    text-decoration: none;
    background-color: #fffada;
}
.ql-editor b,
.ql-editor strong {
    color: #000;
    background-color: #ffee81;
    font-weight: normal;
}
.ql-tooltip {
    display: none;
}
</style>
