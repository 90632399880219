import {createStore} from 'vuex'

const store = createStore({
    state: {
        tips: [],
        activeTip: Object,
        activeTipFocused: false,
        bounds: [],
        isTextInserted: false,
    },
    mutations: {
        clearTips(state) {
            state.tips = []
        },
        addTip(state, tip) {
            state.tips.push(tip)
        },
        dropTip(state, tip) {
            let idx = state.tips.find((el) => {return el.id === tip.id});
            state.tips.splice(idx, 1)
        },
        setActiveTip(state, tip) {
            state.tips.forEach((el, i) => {
                state.tips[i].active = el.id === tip.id
            })
            state.activeTip = tip
        },
        dropActiveTip(state) {
            state.tips.forEach((el, i) => {
                state.tips[i].active = false
            })
            state.activeTip = null
        },
        clearBounds(state) {
            state.bounds = []
        },
        addBounds(state, bounds) {
            state.bounds.push(bounds)
        },
        setIsTextInserted(state, isTextInserted) {
            state.isTextInserted = isTextInserted
        },
        setActiveTipFocused(state, isFocused) {
            state.activeTipFocused = isFocused
        }
    },
    actions: {
        clearTips(context) {
            context.commit('clearTips')
        },
        async fetchTips(context, content) {
            if (content.length <= 1) {
                context.commit('clearTips')
                return
            }

            await fetch('/api/public/v1/proofread/', {
//            await fetch('/chext/api/v1/proofread/', {
                method: 'POST',
                body: JSON.stringify({text: content}),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.json())
                .then(data => {
                    context.commit('clearTips')
                    for (let i = 0; i < data.length; i++) {
                        let currTip = []
                        currTip.id = data[i].id
                        currTip.mark = data[i].mark
                        currTip.title = data[i].title
                        currTip.description = data[i].description
                        currTip.index = data[i].index
                        currTip.size = data[i].size
                        currTip.correct = data[i].correct
                        currTip.top = 0
                        currTip.position = 0
                        if (store.getters.activeTip !== null) {
                            currTip.active = data[i].id === store.getters.activeTip.id
                        } else {
                            currTip.active = false
                        }
                        context.commit('addTip', currTip)
                    }
                })
        },
        dropTip(context, payload) {
            context.commit('dropTip', payload)
        },
        setActiveTip(context, payload) {
            context.commit('setActiveTip', payload)
        },
        dropActiveTip(context) {
            context.commit('dropActiveTip')
        },
        clearBounds(context) {
            context.commit('clearBounds')
        },
        addBounds(context, payload) {
            context.commit('addBounds', payload)
        },
        setIsTextInserted(context, payload) {
            context.commit('setIsTextInserted', payload)
        },
        setActiveTipFocused(context, payload) {
            context.commit('setActiveTipFocused', payload)
        }
    },
    getters: {
        tips(state) {
            return state.tips
        },
        activeTip(state) {
            return state.activeTip
        },
        bounds(state) {
            return state.bounds
        },
        isTextInserted(state) {
            return state.isTextInserted
        },
        activeTipFocused(state) {
            return state.activeTipFocused
        }
    }
})

export default store
