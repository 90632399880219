<template>
    <div v-if="isTextClear" class="pot-well-done">
        <img src="@/assets/well-done.svg" width="39" height="36">
        <p>Мы не&nbsp;нашли<br />к&nbsp;чему придраться</p>
    </div>
    <div v-else-if="activeTip">
        <div class="pot-tip-dumper" :style="{ height: dumperHeight }"></div>
        <div class="pot-tip" :key="tip.id">
            <h3>{{ tip.title }}</h3>
            <p>{{ tip.description }}</p>
            <tip-button v-if="tip.correct !== false" :tip="tip" @click="correctItem">Исправить здесь</tip-button>
            <span v-if="isRepeatedTip">
                <tip-button v-if="tip.correct !== false" :tip="tip" @click="correctItems">Исправить все</tip-button>
            </span>
        </div>
    </div>
</template>

<script>
import TipButton from "@/components/TipButton";
export default {
    name: "TipItem",
    components: {
        TipButton
    },
    computed: {
        tip() {
            return this.$store.getters.activeTip
        },
        activeTip() {
            return this.$store.getters.tips.length !== 0 && this.$store.getters.activeTipFocused
        },
        dumperHeight() {
            let height = 0
            this.$store.getters.bounds.forEach((el) => {
                if (!!this.tip && el.id === this.tip.id) {
                    height = el.inner_top
                }
            })
            return `${height}px`
        },
        isRepeatedTip() {
            let cntr = 0
            if (this.$store.getters.activeTipFocused) {
                this.$store.getters.tips.forEach((el) => {
                    if (el.mark === this.tip.mark) {
                        cntr++
                    }
                })
            }
            return cntr > 1
        },
        isTextClear() {
            return this.$store.getters.isTextInserted && this.$store.getters.tips.length === 0
        }
    },
    mounted() {
        this.$eventHub.on('mouseClickOnSelectedText', (tipId) => {
            this.setActiveTip(tipId)
            this.$store.dispatch('setActiveTipFocused', true)
        })
        this.$eventHub.on('hoveringTextCursorOverSelectedText', (tipId) => {
            this.setActiveTip(tipId)
            this.$store.dispatch('setActiveTipFocused', true)
        })
        this.$eventHub.on('clickOnTheFixButton', () => {
            this.$store.dispatch('setActiveTipFocused', false)
        })
        this.$eventHub.on('clickOnTheFixAllButton', () => {
            this.$store.dispatch('setActiveTipFocused', false)
        })
    },
    methods: {
        setActiveTip(tipId) {
            this.$store.getters.tips.forEach((el) => {
                if (el.id === tipId) {
                    this.$store.dispatch('setActiveTip', el)
                }
            })
        },
        correctItem() {
            this.$eventHub.emit('clickOnTheFixButton', this.tip);
        },
        correctItems() {
            this.$eventHub.emit('clickOnTheFixAllButton', this.tip);
        }
    }
}
</script>

<style scoped>
.pot-tip-dumper {
    background-color: #fff;
    min-height: 1px;
    display: block;
}
.pot-tip {
    /*position: absolute;*/
    width: 270px;
    padding: 0;
    border-radius: 4px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}
.pot-well-done {
    position: sticky;
    top: 10px;
}

@media only screen and (max-width: 767px) {
    .pot-tip-dumper {
        max-height: 1px;
        background: none;
    }
    .pot-tip {
        height: auto;
        min-height: 33.33vh;
    }
    .pot-tip::after,
    ․pot-well-done::after {
        content: " "; /* Older browser do not support empty content */
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }
}

.pot-tip h3 {
    font-family: 'P22UndergroundCYHeavy', Helvetica Neue, Arial, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.33;
    text-align: left;
    margin: 0;
}
.pot-tip p {
    font-family: 'P22UndergroundCYBook', Helvetica Neue, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.33;
    text-align: left;
    color: #000;
    margin: 5px 0 0 0;
}
.pot-well-done p {
    margin: 12px 0 0 0;
    font-family: 'P22UndergroundCYBook', Helvetica Neue, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.33;
}
</style>
