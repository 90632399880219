<template>
    <div id="pot-container">
        <div id="pot-main-window">
            <text-area />
            <clipboard-button />
        </div>
        <div id="pot-right-sidebar" class="pot-tips-container">
            <tip-item></tip-item>
        </div>
    </div>
</template>

<script>
import TextArea from "@/components/TextArea";
import ClipboardButton from "@/components/ClipboardButton";
import TipItem from "@/components/TipItem";

export default {
    name: 'App',
    components: {
        TextArea, ClipboardButton, TipItem
    },
    data() {
        return {
            header: 'Проверка оформления&nbsp;текста'
        }
    }
}
</script>

<style scoped>
#pot-main-window {
    width: 661px;
    padding: 0px 5px;
    background-color: #f0f6f4;
    border-radius: 5px;
    min-height: 200px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    position: relative;
}

@media only screen and (max-width: 767px) {
    #pot-main-window {
        /*position: sticky;*/
        top: 0;
        /*z-index: 3;*/
        width: 95vw;
        height: 66.67vh;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;
    }
}

#pot-right-sidebar {
    width: 275px;
    padding: 0;
    margin-left: 45px;
    position: relative;
}

@media only screen and (max-width: 767px) {
    #pot-right-sidebar {
        margin: 20px 0 0 0;
    }
}
</style>
